import dynamic from "next/dynamic"

export const blockMapping = {
  /**
   * ACF
   */
  "acf/wufoo-form": dynamic(() => import("@/blocks/acf/wufooForm/wufooForm")),
  "acf/faq": dynamic(() => import("@/blocks/acf/faq/faq")),
  "acf/cookie-declaration": dynamic(() =>
    import("@/blocks/acf/cookieDeclaration/cookieDeclaration")
  ),
  "acf/active-campaign": dynamic(() =>
    import("@/blocks/acf/active-campaign/active-campaign")
  ),

  /**
   * Core
   */
  "core/button": dynamic(() => import("@/blocks/core/button/button")),
  "core/buttons": dynamic(() => import("@/blocks/core/buttons/buttons")),
  "core/quote": dynamic(() => import("@/blocks/core/quote/quote")),
  "core/group": dynamic(() => import("@/blocks/core/group/group")),
  "core/heading": dynamic(() => import("@/blocks/core/heading/heading")),
  "core/image": dynamic(() => import("@/blocks/core/image/image")),
  "core/list": dynamic(() => import("@/blocks/core/list/list")),
  "core/paragraph": dynamic(() => import("@/blocks/core/paragraph/paragraph")),
  "core/table": dynamic(() => import("@/blocks/core/table/table")),

  /**
   * Core Custom
   */
  "core/login-form": dynamic(() =>
    import("@/blocks/core/login-form/login-form")
  ),
  "core/password-form": dynamic(() =>
    import("@/blocks/core/password-form/password-form")
  ),

  /**
   * Theme
   */
  "theme/contacts": dynamic(() => import("@/blocks/theme/contacts/contacts")),
  "theme/accordion": dynamic(() =>
    import("@/blocks/theme/accordion/accordion")
  ),
  "theme/accordion-v2": dynamic(() =>
    import("@/blocks/theme/accordion-v2/accordion")
  ),
  "theme/accordion-item": dynamic(() =>
    import("@/blocks/theme/accordion-v2/item")
  ),
  "theme/archive-articles": dynamic(() =>
    import("@/blocks/theme/archive-articles/archive-articles")
  ),
  "theme/archive-header": dynamic(() =>
    import("@/blocks/theme/archive-header/archive-header")
  ),
  "theme/content-block": dynamic(() =>
    import("@/blocks/theme/content-block/content-block")
  ),
  "theme/content-blocks": dynamic(() =>
    import("@/blocks/theme/content-blocks/content-blocks")
  ),
  "theme/files": dynamic(() => import("@/blocks/theme/files/files")),
  "theme/file": dynamic(() => import("@/blocks/theme/files/file")),
  "theme/featured-article": dynamic(() =>
    import("@/blocks/theme/featured-article/featured-article")
  ),
  "theme/hero": dynamic(() => import("@/blocks/theme/hero/hero")),
  "theme/blurbs": dynamic(() => import("@/blocks/theme/blurbs/blurbs")),
  "theme/blurb": dynamic(() => import("@/blocks/theme/blurbs/blurb")),
  "theme/article-teasers": dynamic(() =>
    import("@/blocks/theme/article-teasers/article-teasers")
  ),
  "theme/categories": dynamic(() =>
    import("@/blocks/theme/categories/categories")
  ),
  "theme/link-list": dynamic(() =>
    import("@/blocks/theme/link-list/link-list")
  ),
  "theme/latest-articles": dynamic(() =>
    import("@/blocks/theme/latest-articles/latest-articles")
  ),
  "theme/image-slider": dynamic(() =>
    import("@/blocks/theme/image-slider/image-slider")
  ),
  "theme/youtube": dynamic(() => import("@/blocks/theme/youtube/youtube")),
  "theme/video-teasers": dynamic(() =>
    import("@/blocks/theme/video-teasers/video-teasers")
  ),
  "theme/pdf-list": dynamic(() => import("@/blocks/theme/pdf-list/pdf-list")),
  "theme/post-slider": dynamic(() =>
    import("@/blocks/theme/post-slider/post-slider")
  ),
  "theme/personnel-header": dynamic(() =>
    import("@/blocks/theme/personnel-header/personnel-header")
  ),
  "theme/archive-personnel": dynamic(() =>
    import("@/blocks/theme/archive-personnel/archive-personnel")
  ),
  "theme/useragent-conditional-content": dynamic(() =>
    import(
      "@/blocks/theme/useragent-conditional-content/useragent-conditional-content"
    )
  ),
  "theme/lightbox": dynamic(() => import("@/blocks/theme/lightbox/lightbox")),
  "theme/press-via-tt": dynamic(() =>
    import("@/blocks/theme/press-via-tt/press-via-tt")
  ),
  // "theme/personnel": dynamic(() => import("@/blocks/theme/personnel")),
  "theme/google-maps": dynamic(() =>
    import("@/blocks/theme/google-maps/google-maps")
  ),
  "theme/spotify": dynamic(() => import("@/blocks/theme/spotify/spotify")),
  "theme/gravity-form": dynamic(() =>
    import("@/blocks/theme/gravity-form/gravity-form")
  ),
  "theme/gravity-form-result": dynamic(() =>
    import("@/blocks/theme/gravity-form-result")
  ),
  "theme/text-block": dynamic(() =>
    import("@/blocks/theme/text-block/text-block")
  ),
  "theme/text-block-heading": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-heading")
  ),
  "theme/text-block-body": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-body")
  ),
  "theme/multisoft-snippet": dynamic(() =>
    import("@/blocks/theme/multisoft-snippet/multisoft-snippet")
  ),
  "theme/content-with-form": dynamic(() =>
    import("@/blocks/theme/content-with-form/content-with-form")
  ),
  "theme/form-content": dynamic(() =>
    import("@/blocks/theme/form-content/form-content")
  ),
  "theme/sound-clip": dynamic(() =>
    import("@/blocks/theme/sound-clip/sound-clip")
  ),
  "theme/icon": dynamic(() => import("@/blocks/theme/icon/icon")),
  "theme/tabbed-content-wrapper": dynamic(() =>
    import("@/blocks/theme/tabbed-content/wrapper/wrapper")
  ),
  "theme/tabbed-content-tab": dynamic(() =>
    import("@/blocks/theme/tabbed-content/tab/tab")
  ),
  "theme/article-hero": dynamic(() =>
    import("@/blocks/theme/article-hero/article-hero")
  ),
  "theme/article-tag": dynamic(() =>
    import("@/blocks/theme/article-hero/article-tag")
  ),
  "theme/article-terms": dynamic(() =>
    import("@/blocks/theme/article-hero/article-terms")
  ),
  "theme/article-meta": dynamic(() =>
    import("@/blocks/theme/article-hero/article-meta")
  ),
  "theme/article-sidebars": dynamic(() =>
    import("@/blocks/theme/article-sidebars/article-sidebars")
  ),
  "theme/article-cta": dynamic(() =>
    import("@/blocks/theme/article-cta/article-cta")
  ),
  "theme/search-header": dynamic(() =>
    import("@/blocks/theme/search-header/search-header")
  ),
  "theme/search-results": dynamic(() =>
    import("@/blocks/theme/search-results/search-results")
  ),
  "theme/quote-slider": dynamic(() =>
    import("@/blocks/theme/quote-slider/quote-slider")
  ),
  "theme/products-header": dynamic(() =>
    import("@/blocks/theme/products-header/products-header")
  ),
  "theme/archive-products": dynamic(() =>
    import("@/blocks/theme/archive-products/archive-products")
  ),
  "theme/product-header": dynamic(() =>
    import("@/blocks/theme/product-single/product-header")
  ),
  "theme/product-info": dynamic(() =>
    import("@/blocks/theme/product-single/product-info")
  ),
  "theme/archive-ingredients": dynamic(() =>
    import("@/blocks/theme/archive-ingredients/archive-ingredients")
  ),
  "theme/latest-products": dynamic(() =>
    import("@/blocks/theme/latest-products/latest-products")
  ),
  "theme/project-single": dynamic(() =>
    import("@/blocks/theme/project-single/project-single")
  ),
  "theme/archive-projects": dynamic(() =>
    import("@/blocks/theme/archive-projects/archive-projects")
  )
}
