import Link from "next/link"
import { useRouter } from "next/router"
import { useMemo, useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useCopy } from "@/lib/contexts/appContext"
import Button from "@/components/common/button/button"
import Logo from "@/common/logos/logo"
import Overlay from "./overlay"
import AdminBar from "./admin-bar"
import MenuTop from "./menus/top"
import Featured from "./menus/featured"
import Primary from "./menus/primary"
import { Search } from "./search"
import classNames from "classnames"
import styles from "./header.module.css"

export default function Header() {
  const defaultState = useMemo(() => {
    return {
      search: false,
      supportUs: false,
      offCanvas: false,
      overlay: false
    }
  }, [])

  const { general } = useCopy()

  const isMobile = useMediaPredicate("(max-width: 640px)")
  const isNarrow = useMediaPredicate("(max-width: 1024px)")
  const [hasMounted, setMounted] = useState(false)

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const [state, setState] = useState(defaultState)
  const router = useRouter()

  const handleClick = (location, open) => {
    setState({
      ...defaultState,
      overlay: open,
      [location]: open
    })
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && state.overlay) {
        setState({ ...defaultState })
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [state.overlay, defaultState])

  useEffect(() => {
    const handleRouteChange = () => setState({ ...defaultState })

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events, defaultState])

  const classes = [styles.header, styles.opaque]

  return (
    <>
      <header id="header" className={classNames(classes)}>
        <AdminBar />
        <MenuTop hasMounted={hasMounted} isNarrow={isNarrow} />
        <div className="menu-bar">
          <Link href="/" prefetch={false} legacyBehavior>
            <a className={styles.logo}>
              <Logo className="logo" />
            </a>
          </Link>
          <Featured
            isNarrow={isNarrow}
            hasMounted={hasMounted}
            handleOverlay={handleClick}
          />
          <Search isActive={state.search} setActive={handleClick} />
          <Licensing general={general} />
          <Primary
            hasMounted={hasMounted}
            isNarrow={isNarrow}
            isMobile={isMobile}
            isActive={state.offCanvas}
            setActive={handleClick}
          />
        </div>
      </header>
      <Overlay active={state.overlay} />
    </>
  )
}

export function getMenuColor() {
  let textColor = "text-black-100"
  let borderColor = "border-black-100/10"
  let bgColor = "bg-white"

  return [textColor, borderColor, bgColor].join(" ")
}

export function Licensing({ general }) {
  if (!general.ctaButton?.title) {
    return null
  }

  return (
    <Button
      tagName="a"
      className="hidden mx-3 sm:block max-w-[170px]"
      href={general.ctaButton.url}
      target={general.ctaButton.target}
      theme="good-blue">
      {general.ctaButton.title}
    </Button>
  )
}
